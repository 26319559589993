* {
  box-sizing: border-box;
}

html,
body {
  margin: 0;
  padding: 0;
  font-family: Arial, Helvetica, sans-serif;
  height: 100%;
}

.root {
  height: 100%;
}

.app {
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: center;
  height: 100%;

  .container {
    width: 568px;
    margin: auto;
  }
}
